<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon class="text-body" size="21" icon="HelpCircleIcon" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Butuh Bantuan ?
        </h4>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="contact in contacts"
        :key="contact.subtitle"
        :href="`https://wa.me/${contact.phone_number}`"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              <i class="ti ti-brand-whatsapp text-success" />
              {{ contact.name }}
            </span>
          </p>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- TODO: enable mark all reads -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="downloadPanduan"
      >
        <i class="ti ti-download" />
        Download Panduan
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  // BAvatar,
  BButton,
  // BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { ref, onMounted } from '@vue/composition-api'
import client from '@/libs/http/axios-config'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    // BAvatar,
    VuePerfectScrollbar,
    BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    const contacts = ref([])

    onMounted(async () => {
      await client
        .get('/utils/school-admin')
        .then((response) => {
          contacts.value = [response.data.data]
        })
        .catch((error) => {
          console.error(error)
        })
    })

    const urlPanduan = [
      {
        name: 'admin',
        guidename: 'Manual Book Kelas Jateng Admin Sekolah',
        file: '/guide/Manual Book Kelas Jateng_Admin Sekolah.pdf',
      },
      {
        name: 'teacher',
        guidename: 'Manual Book Kelas Jateng Guru',
        file: '/guide/Manual Book Kelas Jateng_Guru.pdf',
      },
      {
        name: 'student',
        guidename: 'Manual Book Kelas Jateng Siswa',
        file: '/guide/Manual Book Kelas Jateng_Siswa.pdf',
      },
    ]
    const downloadPanduan = () => {
      const userRole = JSON.parse(localStorage.getItem('userData'))
        .roles[0].name.toLowerCase()
        .replaceAll(' ', '')
      const url = urlPanduan.find((item) => item.name === userRole).file

      console.log(userRole)

      window.open(url, '_blank', 'noreferrer')
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      contacts,
      perfectScrollbarSettings,
      downloadPanduan,
    }
  },
}
</script>

<style></style>
