<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifikasi
        </h4>
        <b-badge pill variant="light-primary">
          {{ notifications.length }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.subtitle"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- TODO: enable mark all reads -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="handleNotificationsClick"
      >
        Tandai Telah Dibaca
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  // BAvatar,
  BButton,
  // BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { ref, onMounted } from '@vue/composition-api'
import client from '@/libs/http/axios-config'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    // BAvatar,
    VuePerfectScrollbar,
    BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = ref([])
    /* eslint-disable global-require */

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    onMounted(async () => {
      await client
        .get('/students/notifications?read=false')
        .then((response) => {
          notifications.value = response.data.data.map((notification) => {
            return {
              title: notification.data.status,
              subtitle: notification.data.data.title,
              type: 'light-info',
            }
          })
        })
        .catch((error) => {
          console.error(error)
        })
    })

    async function handleNotificationsClick() {
      const data = new FormData()
      data.append('_method', 'PUT')

      await client
        .post('/students/notifications/read-all', data)
        .then((response) => {
          // reload notifications list
          client
            .get('/students/notifications?read=false')
            .then((response) => {
              notifications.value = response.data.data.map((notification) => {
                return {
                  title: notification.data.status,
                  subtitle: notification.data.data.title,
                  type: 'light-info',
                }
              })
            })
            .catch((error) => {
              console.error(error)
            })
        })
        .catch((error) => {
          console.error(error)
        })
    }

    return {
      notifications,
      perfectScrollbarSettings,
      handleNotificationsClick,
    }
  },
}
</script>

<style></style>
