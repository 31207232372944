export default {
  superadmin: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'superadmin-dashboard',
    },
    {
      title: 'Berita',
      icon: 'ti-news',
      route: 'superadmin-news',
    },
    {
      title: 'Tahun Ajaran',
      icon: 'ti-calendar-stats',
      route: 'superadmin-years',
    },
    {
      title: 'Sekolah',
      icon: 'ti-school',
      route: 'superadmin-schools',
    },
    {
      title: 'Guru',
      icon: 'ti-user',
      route: 'superadmin-teachers',
    },
    {
      title: 'Siswa',
      icon: 'ti-users',
      route: 'superadmin-students',
    },
    {
      title: 'Bank Materi',
      icon: 'ti-books',
      route: 'superadmin-material-bank',
    },
    // {
    //   header: 'Bantuan',
    // },
    // {
    //   title: 'Panduan',
    //   icon: 'ti-help',
    //   route: 'panduan',
    // },
  ],
  admin: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'admin-dashboard',
    },
    // {
    //   title: 'Tahun Ajaran',
    //   icon: 'ti-calendar-stats',
    //   route: 'admin-study-years',
    // },
    {
      title: 'Guru',
      icon: 'ti-user',
      route: 'admin-teachers',
    },
    {
      title: 'Siswa',
      icon: 'ti-users',
      route: 'admin-students',
    },
    {
      title: 'Kelas',
      icon: 'ti-school',
      route: 'admin-classrooms',
    },
    {
      title: 'Alumni',
      icon: 'ti-user-search',
      route: 'admin-alumni',
    },

    // Bantuan
    // {
    //   header: 'Bantuan',
    // },
    // {
    //   title: 'Panduan',
    //   icon: 'ti-help',
    //   route: 'panduan',
    // },
  ],
  teacher: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'teacher-dashboard',
    },
    // {
    //   title: 'Kelas',
    //   icon: 'ti-school',
    //   children: [
    //     {
    //       title: 'Daftar Kelas',
    //       route: 'teacher-classroom',
    //     },
    //     {
    //       title: 'Buat Kelas',
    //       route: 'teacher-classroom-add',
    //     },
    //   ],
    // },
    {
      title: 'Kelas',
      icon: 'ti-school',
      route: 'teacher-classroom',
    },

    // Bantuan
    // {
    //   header: 'Bantuan',
    // },
    // {
    //   title: 'Panduan',
    //   icon: 'ti-help',
    //   route: 'panduan',
    // },
  ],
  student: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'student-dashboard',
    },
    {
      title: 'Jadwal',
      icon: 'ti-calendar-event',
      route: 'student-schedule',
    },
    {
      title: 'Kelas',
      icon: 'ti-school',
      route: 'student-classroom',
    },
    // {
    //   title: 'Ujian',
    //   icon: 'ti-file-text',
    //   route: 'student-exam',
    // },
    // {
    //   title: 'Hasil Belajar',
    //   icon: 'ti-stars',
    //   route: 'student-report',
    // },

    // Bantuan
    // {
    //   header: 'Bantuan',
    // },
    // {
    //   title: 'Panduan',
    //   icon: 'ti-help',
    //   route: 'panduan',
    // },
  ],
}
