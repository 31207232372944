<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'light' || skin === 'bordered' ? 'menu-light' : 'menu-dark',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              :to="{ name: checkAuthorizeRole('dashboard') }"
            >
              <span class="brand-logo ">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <h5 class="brand-text text-natural">
                {{ appName }}
              </h5>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle ">
              <i
                class="ti ti-chevron-left d-block d-xl-none nav-toggle-icon"
                @click="toggleVerticalMenuActive"
              />
              <i
                :class="collapseTogglerIconFeather"
                class="ti d-none d-xl-block collapse-toggle-icon nav-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <div class="main-menu-wrapper d-flex flex-column justify-content-between">
      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="
          (evt) => {
            shallShadowBottom = evt.srcElement.scrollTop > 0
          }
        "
      >
        <vertical-nav-menu-items
          :items="navMenuItems"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
      <!-- /main menu content-->

      <!-- main menu footer-->
      <div v-if="userRole !== 'superadmin'" class="main-menu-footer">
        <div class="footer-text-wrapper">
          <h4>{{ school.name }}</h4>
        </div>
        <b-img class="mt-1" :src="school.logo" alt="logo" />
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const userRole = JSON.parse(localStorage.getItem('userData'))
      .roles[0].name.toLowerCase()
      .replaceAll(' ', '')

    let school = ref({ name: '', logo: '' })
    if (userRole !== 'superadmin') {
      const userData = JSON.parse(localStorage.getItem('userData'))
      school = ref(userData.school)
    }

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === 'unpinned'
        ? 'ti-chevron-right'
        : 'ti-chevron-left'
    )

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      checkAuthorizeRole,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      userRole,
      school,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';

.dark-layout {
  .brand-text {
    color: #d0d2d6 !important;
  }
}

.text-natural {
  color: #4b465c !important;
}

.nav-toggle-icon {
  margin-top: 1.35rem;
  font-size: 18px;
  line-height: 35px;
}

.main-menu-wrapper {
  height: calc(100% - 4.45rem) !important;

  .main-menu-footer {
    padding: 24px;
    display: flex;
    flex-direction: column;

    img {
      max-width: 100px;
    }
  }
}

.menu-collapsed {
  .footer-text-wrapper {
    display: none;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
}

.expanded {
  .footer-text-wrapper {
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
