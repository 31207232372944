<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->

      <b-navbar-nav class="nav">
        <search-bar
          v-if="
            this.$route.meta.role === 'student' ||
              this.$route.meta.role === 'teacher'
          "
        />
      </b-navbar-nav>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <help class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <notification-dropdown v-if="this.$route.meta.role === 'student'" />
      <user-dropdown />

      <!-- Sidebar Toggler -->
      <b-link
        class="nav-link "
        @click="isUserPersonalSidebarOpen = !isUserPersonalSidebarOpen"
      >
        <i class="ti ti-layout-grid-add sidebar-toggler" />
      </b-link>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import useUserPersonalSidebar from '@core/layouts/components/user-personal-sidebar/useUserPersonalSidebar'
import SearchBar from './components/SearchBar.vue'
// import Bookmarks from './components/Bookmarks.vue'
import DarkToggler from './components/DarkToggler.vue'
import Help from './components/Help.vue'
// import Locale from './components/Locale.vue'
// import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    // Navbar Components
    // Bookmarks,
    DarkToggler,
    Help,
    BLink,
    BNavbarNav,
    NotificationDropdown,
    UserDropdown,
    SearchBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { isUserPersonalSidebarOpen } = useUserPersonalSidebar()

    return {
      isUserPersonalSidebarOpen,
    }
  },
}
</script>

<style lang="scss">
.dark-layout {
  .sidebar-toggler {
    color: #d0d2d6;
  }
}

.sidebar-toggler {
  font-size: 1.5rem;
}
</style>
