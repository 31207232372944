<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />

    <!-- filter kelas -->
    <div v-show="isFilter" class="content-wrapper card p-2">
      <h6>
        <i class="ti ti-adjustments-alt" />
        Filter
      </h6>
      <b-row>
        <b-col lg="6">
          <label>
            Sekolah
          </label>
          <v-select
            v-model="filter.school"
            placeholder="Pilih Sekolah"
            :reduce="(option) => option.id"
            :options="schools"
          />
        </b-col>

        <b-col lg="6">
          <label>
            Tahun Ajaran
          </label>
          <v-select
            v-model="filter.schoolYear"
            placeholder="Pilih Tahun Ajaran"
            :reduce="(option) => option.id"
            :options="schoolYears"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col class="d-flex justify-content-end">
          <b-button
            class="mr-1"
            size="md"
            variant="outline-secondary"
            @click="clearFilter"
          >
            <i class="ti ti-trash" />
            Hapus Filter
          </b-button>
          <b-button size="md" variant="primary" @click="applyFilter">
            <i class="ti ti-check" />
            Terapkan
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>

      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import { BRow, BCol, BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import { ref, onMounted } from '@vue/composition-api'
import client from '@/libs/http/axios-config'

export default {
  components: {
    AppBreadcrumb,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      filter: {
        school: null,
        schoolYear: null,
      },
    }
  },
  computed: {
    isFilter() {
      return store.state.search.isFilter
    },
  },
  watch: {
    isFilter() {
      if (this.isFilter) {
        this.getSchoolYears()
        this.getSchool()
      }
    },
    filter: {
      handler() {
        store.commit('search/SET_FILTER', this.filter)
      },
      deep: true,
    },
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()
    const schoolYears = ref([])
    const schools = ref([])

    const clearFilter = () => {
      store.commit('search/SET_IS_FILTER', false)
      store.commit('search/SET_IS_FILTER_APPLIED', false)
      store.commit('search/SET_FILTER', {
        school: null,
        schoolYear: null,
      })
    }

    const applyFilter = () => {
      store.commit('search/SET_IS_FILTER_APPLIED', true)
    }

    const getSchoolYears = async () => {
      await client
        .get('/utils/school-years')
        .then((response) => {
          schoolYears.value = response.data.data.map((item) => {
            return {
              label: `${item.period} - ${item.name}`,
              id: item.id,
            }
          })

          schoolYears.value.unshift({
            label: 'Semua',
            id: null,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const getSchool = async () => {
      await client
        .get('/admin/schools')
        .then((response) => {
          schools.value = response.data.data.map((item) => {
            return {
              label: item.name,
              id: item.id,
            }
          })

          schools.value.unshift({
            label: 'Semua',
            id: null,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }

    return {
      schoolYears,
      schools,
      clearFilter,
      applyFilter,
      routerTransition,
      contentWidth,
      getSchoolYears,
      getSchool,
    }
  },
}
</script>

<style></style>
