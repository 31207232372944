<template>
  <layout-vertical :nav-menu-items="navMenuItems">
    <router-view />
    <!-- <app-customizer slot="customizer" /> -->
    <user-personal-sidebar slot="user-personal-sidebar" />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import UserPersonalSidebar from '@core/layouts/components/user-personal-sidebar/UserPersonalSidebar.vue'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'

export default {
  components: {
    // AppCustomizer,
    UserPersonalSidebar,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      userRole: 'student',
    }
  },
  created() {
    this.userRole = JSON.parse(localStorage.getItem('userData')).roles[0].name
  },
  computed: {
    // Condition : Nav menu by role
    navMenuItems() {
      if (this.userRole.toLowerCase() === 'student') {
        return navMenuItems.student
      }
      if (this.userRole.toLowerCase() === 'teacher') {
        return navMenuItems.teacher
      }
      if (this.userRole.toLowerCase() === 'admin') {
        return navMenuItems.admin
      }
      return navMenuItems.superadmin
    },
  },
}
</script>
